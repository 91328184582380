import React from "react";
import styled, { keyframes, css } from "styled-components";
import { fadeInUp } from "react-animations";

// Styled Component
const StyledComponent = styled.div`
  ${(props) =>
    props.animationStyle
      ? css`
          animation: ${keyframes`${props.animationStyle}`};
        `
      : css`
          animation: ${keyframes`${fadeInUp}`};
        `}
  ${(props) =>
    props.duration &&
    css`
      animation-duration: ${props.duration};
    `}
  ${(props) =>
    props.delay &&
    css`
      animation-delay: ${props.delay};
    `}
`;

// React-Animation Component with Styled-Component
const FadeInUp = (props) => {
  const { inView, duration, animationStyle } = props;
  return inView ? (
    <StyledComponent duration={duration} animationStyle={animationStyle}>
      {props.children}
    </StyledComponent>
  ) : (
    <div style={{ opacity: 0 }}>{props.children}</div>
  );
};

export default FadeInUp;
