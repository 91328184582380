import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const Spinner = () => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          background: "#fafafa",
          zIndex: "11",
        }}
      ></div>
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          zIndex: "12",
        }}
      >
        <CircularProgress size={105} />
      </div>
    </>
  );
};

export default Spinner;
