import React, { useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { Link } from "react-router-dom";
import scrollToElement from "scroll-to-element";
import { useLocation, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  iconBtn: {
    backgroundColor: "#004667",
    "&:hover": {
      backgroundColor: "#004667",
      opacity: "0.7",
    },
  },
}));

const AntTabs = withStyles({
  root: {},
  indicator: {
    backgroundColor: "#004667",
  },
})(Tabs);

// Individual Tab
const AntTab = withStyles((theme) => ({
  root: {
    color: "rgb(218, 218, 218)",
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "white",
      opacity: 1,
    },
    "&$selected": {
      color: "white",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "white",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

// Whole Tab with Tabs
export default function CustomizedTabs(props) {
  const location = useLocation();
  const history = useHistory();
  const { fullWidth } = props;
  const classes = useStyles();
  // State for Active Index
  const [value, setValue] = React.useState(0);
  // Handling Index Function
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setValue(0);
        break;
      case "/member":
        setValue(1);
        break;
      case "/our-services":
        setValue(2);
        break;
      case "/portfolio":
        setValue(4);
        break;
      case "/blogs":
        setValue(5);
        break;
      case `/blogs${location.pathname.replace("/blogs", "")}`:
        setValue(5);
        break;
      case "/404":
        setValue(6);
        break;
      default:
        setValue(0);
    }
  }, [location]);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div id="mainNavBar" style={{ width: "80%" }}>
        <AntTabs
          value={value}
          onChange={handleChange}
          aria-label="ant example"
          centered
        >
          <AntTab label="HOME" component={Link} to="/" />
          <AntTab label="OUR TEAM" component={Link} to="/member" />
          <AntTab label="OUR SERVICE" component={Link} to="/our-services" />
          <AntTab
            label="CONTACT US"
            onClick={() => {
              if (location.pathname === "/") {
                scrollToElement("#contact-us", {
                  offset: -100,
                });
              } else {
                history.push("/#contact");
              }
            }}
          />
          <AntTab label="PORTFOLIO" component={Link} to="/portfolio" />
          <AntTab label="BLOGS" component={Link} to="/blogs" />
        </AntTabs>
      </div>
      <div
        style={{
          color: "Black",
          width: "20%",
          display: "flex",
          justifyContent: "flex-end",
          marginRight: fullWidth ? "133px" : "0.5%",
        }}
      >
        {!fullWidth ? <Divider orientation="vertical" flexItem /> : null}
        <a href="https://www.facebook.com/appiwat.it" target="blank">
          <IconButton className={classes.iconBtn}>
            <FacebookIcon
              style={{
                color: "#0D88F0",
              }}
            />
          </IconButton>
        </a>
        {/* {!fullWidth ? <Divider orientation="vertical" flexItem /> : null}
        <IconButton className={classes.iconBtn}>
          <InstagramIcon style={{ color: "#ee4a65" }} />
        </IconButton> */}
        {!fullWidth ? <Divider orientation="vertical" flexItem /> : null}
        <a
          href="https://www.linkedin.com/company/fourmi-appiwat-it"
          target="blank"
        >
          <IconButton className={classes.iconBtn}>
            <LinkedInIcon style={{ color: "50ABF1" }} />
          </IconButton>
        </a>
      </div>
    </div>
  );
}
