import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import logo from "../../../assets/images/logo.jpg";
import classes from "./mobileNavBar.module.css";
import PhoneNum from "../TopBar/PhoneNum/PhoneNum";
import Location from "../TopBar/Location/location";
import Email from "../TopBar/Email/email";
import SideDrawer from "./sideDrawer/sideDrawer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  burgerBtn: {
    transition: "all 0.3s ease-in-out",
    background: "transparent",
    "&:hover": {
      backgroundColor: "#00405d",
      transform: "scale(1.1,1.1)",
    },
    "&:active": {
      opacity: "0.6",
      transform: "rotate(-90deg)",
    },
  },
}));

const MobileNavBar = (props) => {
  // State for Handling SideDrawer
  const [state, setState] = React.useState(false);
  // SideDrawer State Handler Function
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const styles = useStyles();
  // Breakpoints Props
  const { sm, xs } = props;
  // Class for Elemnet
  const logoClass = [classes.logoContainer];
  // Info on Mobile Bar
  let info = null;
  // Add Class and Element based on Breakpoints
  if (xs && !sm) {
    logoClass.push(classes.xsLogoContainer);
    info = <PhoneNum xs={true} />;
  } else if (xs && sm) {
    info = (
      <>
        <div className={classes.phoneNum}>
          <PhoneNum sm={true} />
        </div>
        <div className={classes.email}>
          <Email sm={true} />
        </div>
        <div className={classes.location}>
          <Location sm={true} />
        </div>
      </>
    );
  }
  return (
    <>
      <SideDrawer toggleDrawer={toggleDrawer} state={state} />
      <div className={styles.root}>
        <AppBar
          position="fixed"
          style={{
            backgroundColor: "#004667",
            position: "fixed",
            zIndex: "10",
          }}
        >
          <Toolbar>
            <div className={classes.container}>
              <div className={classes.buttonAndLogo}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                  className={styles.burgerBtn}
                >
                  <MenuIcon />
                </IconButton>
                <div>
                  <div className={logoClass.join(" ")}>
                    <img src={logo} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className={classes.phone}>{info}</div>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};

export default MobileNavBar;
