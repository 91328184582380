import React from "react";
import { useInView } from "react-intersection-observer";
import FadeInUp from "./StyledComponent";

const IntersectAnimation = (props) => {
  const { duration, delay, once, threshold, animationStyle } = props;
  // Set Up Intersection Observer
  const [ref, inView] = useInView({
    threshold: threshold || 0.4,
    triggerOnce: once === "false" ? false : true,
  });
  // Props

  return (
    <div
      ref={ref}
      style={
        (props.fullWidth && { width: "100%" },
        { position: "relative", zIndex: "9" })
      }
      className={props.className ? props.className : ""}
    >
      <FadeInUp
        inView={inView}
        duration={duration}
        delay={delay}
        animationStyle={animationStyle}
      >
        {props.children}
      </FadeInUp>
    </div>
  );
};

export default IntersectAnimation;
