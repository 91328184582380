import TopBar from "./TopBar/topBar";
import MobileNavBar from "./mobileNavBar/mobileNavBar";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const ResponsiveBars = () => {
  // Breakpoints to respond
  const theme = useTheme();
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.up("xs"));
  let bar = null;
  // Checks for which Bar to return
  if (matchesMD) {
    // Shows Top Bar with Logo and Info
    bar = <TopBar md={matchesMD} lg={matchesLG} xl={matchesXL} />;
  } else {
    // Shows Mobile Bar
    bar = <MobileNavBar xs={matchesXS} sm={matchesSM} />;
  }
  return bar;
};

export default ResponsiveBars;
