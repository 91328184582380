import React from "react";
import {
  Container,
  Grid,
  makeStyles,
  Box,
  Typography,
  Divider,
  Avatar,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import BusinessIcon from "@material-ui/icons/Business";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.jpg";
import IntersectAnimation from "../../utilities/IntersectionAnimation";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "50vh",
    backgroundColor: "#004866",
    color: "white",
    padding: "1.2rem",
  },
  sectionContainerTop: {
    padding: "0 3em",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: "0",
    },
  },
  sectionContainerBottom: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  sectionSubTextContainer: {
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      // alignItems: "center",
    },
    "& a": {
      lineHeight: "2rem",
    },
  },
  sectionHeaderText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      // textAlign: "center",
    },
  },
  divider: {
    height: ".2rem",
    backgroundColor: "white",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  img: {
    height: theme.spacing(7),
    width: theme.spacing(7),
    objectFit: "cover",
  },
  imgContainer: {
    backgroundColor: "white",
    height: theme.spacing(9),
    width: theme.spacing(9),
  },
}));

const Footer = () => {
  const classes = useStyles();
  const year = new Date().getFullYear();
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Box className={classes.root}>
      <Container>
        <Grid
          container
          justifyContent="space-between"
          className={classes.sectionContainerTop}
        >
          <IntersectAnimation duration="0.6s">
            <Grid item>
              <Typography variant="h4" className={classes.sectionHeaderText}>
                Contact
              </Typography>
              <Grid
                container
                direction="column"
                className={classes.sectionSubTextContainer}
              >
                <Box display="flex" alignItems="center">
                  <PhoneIcon />
                  <Typography style={{ paddingLeft: "0.5rem" }}>
                    <a href="tel:855966037163">+855-96-603-716-3</a>
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <EmailIcon />
                  <Typography style={{ paddingLeft: "0.5rem" }}>
                    <a href="mailto:info@appiwat.com">info@appiwat.com</a>
                  </Typography>
                </Box>
                <Box display="flex" alignItems={matchXs ? "start" : "center"}>
                  <BusinessIcon />
                  <Typography style={{ paddingLeft: "0.5rem" }}>
                    2nd fl., St. 348, Sangkat Toul Svay Prey 1, Phnom Penh,
                    12308
                  </Typography>
                </Box>
                {/* <Typography gutterBottom style={{ display: "flex", alignItems: "center"}}><PhoneIcon />&nbsp;&nbsp;&nbsp;<a href="tel:855966037163">+855-96-603-716-3</a></Typography>
                <Typography gutterBottom style={{ display: "flex", alignItems: "center"}}><EmailIcon />&nbsp;&nbsp;&nbsp;<a href="mailto:info@appiwat.com">info@appiwat.com</a></Typography>
                <Typography gutterBottom style={{ display: "flex", alignItems: "center"}}><BusinessIcon />&nbsp;&nbsp;&nbsp;2nd fl., St. 348, Sangkat Toul Svay Prey 1, Phnom Penh, 12308</Typography> */}
                {/* <Typography>Phnom Penh, 12308</Typography> */}
              </Grid>
            </Grid>
          </IntersectAnimation>
          {/* <IntersectAnimation duration="0.6s">
            <Grid item>
              <Typography variant="h4" className={classes.sectionHeaderText}>
                About
              </Typography>
              <Grid
                container
                direction="column"
                className={classes.sectionSubTextContainer}
              >
                <Link to="#">About</Link>
                <Link to="#">Admissions</Link>
                <Link to="#">Campus Life</Link>
                <Link to="#">News</Link>
              </Grid>
            </Grid>
          </IntersectAnimation> */}
          <IntersectAnimation duration="0.6s">
            <Grid item>
              <Typography variant="h4" className={classes.sectionHeaderText}>
                Sitemap
              </Typography>
              <Grid
                container
                direction="column"
                className={classes.sectionSubTextContainer}
              >
                <Link to="/">Home</Link>
                <Link to="/member">Our Team</Link>
                <Link to="/our-services">Our Service</Link>
              </Grid>
            </Grid>
          </IntersectAnimation>
        </Grid>
        <Divider className={classes.divider}></Divider>
        <IntersectAnimation duration="0.85s">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.sectionContainerBottom}
            spacing={3}
          >
            <Grid item>
              <Avatar className={classes.imgContainer}>
                <img src={logo} className={classes.img} alt="Logo" />
              </Avatar>
            </Grid>
            <Grid item>
              <Typography align="center">
                &copy;{year} All Rights Reserved | Fourmi Appiwat IT PLC.
              </Typography>
            </Grid>
            <Grid>
              <Grid container justifyContent="space-around">
                <a href="https://www.facebook.com/appiwat.it" target="blank">
                  <IconButton style={{ marginRight: "1rem" }}>
                    <FacebookIcon />
                  </IconButton>
                </a>
                <IconButton style={{ marginRight: "1rem" }}>
                  <TwitterIcon />
                </IconButton>
                <IconButton>
                  <InstagramIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </IntersectAnimation>
      </Container>
    </Box>
  );
};

export default Footer;
