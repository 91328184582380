import React from "react";

import classes from "../topBar.module.css";

const Location = (props) => {
  // Breakpoint props
  const { sm } = props;
  // Class for Element
  const locationClass = [classes.container];
  // Add class based on Breakpoint
  if (sm) {
    locationClass.push(classes.smContainer);
  }

  return (
    <div className={locationClass.join(" ")}>
      <div className={classes.iconContainer}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="28"
          viewBox="0 0 24 24"
          width="28"
          style={{ fill: "red", marginRight: "10%" }}
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
        </svg>
      </div>
      <div className={classes.textContainer}>
        <div>LOCATION</div>
        <div>St 348 Sangkat Toul Svay Prey 1</div>
      </div>
    </div>
  );
};

export default Location;
