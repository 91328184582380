import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import MailIcon from "@material-ui/icons/Mail";
import GroupIcon from "@material-ui/icons/Group";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { MdDesignServices } from "react-icons/md"
import { SiHandshake } from "react-icons/si"
import { useHistory, useLocation } from "react-router";
import scrollToElement from "scroll-to-element";

import Logo from "../../../../assets/images/logo.png";

const CustomizedSiHandshake = () => <SiHandshake style={{ width: '23px', height: '23px' }} />;
const CustomizedMdDesignServices = () => <MdDesignServices style={{ width: '23px', height: '23px' }} />;

const useStyles = makeStyles({
  list: {
    width: 250,
    padding: "0 10px",
  },
  logoContainer: {
    marginTop: "20px",
    marginBottom: "10px",
    width: "228px",
    height: "50px",
    backgroundImage: `url(${Logo})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
});

const SiderDrawer = (props) => {
  const { toggleDrawer, state } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  // Full List in Menu
  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className={classes.logoContainer}></div>
      <List>
        <ListItem
          button
          key={1}
          onClick={() => {
            history.push({ pathname: "/" });
          }}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={"HOME"} />
        </ListItem>
        <ListItem
          button
          key={2}
          onClick={() => {
            history.push({
              pathname: "/member",
            });
          }}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary={"OUR TEAM"} />
        </ListItem>
        <ListItem
          button
          key={6}
          onClick={() => {
            history.push({ pathname: "/our-services" });
          }}
        >
          <ListItemIcon>
            {CustomizedSiHandshake()}
          </ListItemIcon>
          <ListItemText primary={"OUR SERVICE"} />
        </ListItem>
        <ListItem
          button
          key={5}
          onClick={() => {
            if (location.pathname === "/") {
              scrollToElement("#contactUs", {
                offset: -50,
              });
            } else {
              history.push("/#contact");
            }
          }}
        >
          <ListItemIcon>
            <MailIcon />
          </ListItemIcon>
          <ListItemText primary={"CONTACT US"} />
        </ListItem>
        <ListItem
          button
          key={8}
          onClick={() => {
            history.push({ pathname: "/portfolio" });
          }}
        >
          <ListItemIcon>
            {CustomizedMdDesignServices()}
          </ListItemIcon>
          <ListItemText primary={"PORTFOLIO"} />
        </ListItem>
        <ListItem
          button
          key={8}
          onClick={() => {
            history.push({ pathname: "/blogs" });
          }}
        >
          <ListItemIcon>
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText primary={"BLOGS"} />
        </ListItem>
      </List>
    </div>
  );

  const left = "left";
  return (
    <div>
      {
        <React.Fragment key={left}>
          <SwipeableDrawer
            anchor={left}
            open={state}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {list()}
          </SwipeableDrawer>
        </React.Fragment>
      }
    </div>
  );
};

export default SiderDrawer;
