import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import { useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import cssClasses from "./navBar.module.css";
import Tabs from "./Tabs/tabs";
import TopBar from "../ResponsiveBar/TopBar/topBar";

const NavBar = () => {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  // State to set NavBar's Width
  const [fullWidth, setFullWidth] = useState(false);
  // Add and Remove Scroll Event
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);
  // Check Scroll Height and setState
  const scrollHandler = () => {
    window.pageYOffset >= 100 ? setFullWidth(true) : setFullWidth(false);
  };
  // Class for NavBar
  const navBarClass = [cssClasses.navBar];
  // Check state to whether make it sticky
  if (fullWidth) {
    navBarClass.push(cssClasses.stickyNavBar);
  }

  return matchesMD ? (
    <div className={navBarClass.join(" ")}>
      <AppBar
        position="static"
        style={{
          height: fullWidth ? "auto" : "70px",
          justifyContent: "center",
          backgroundColor: "#004667",
        }}
      >
        {fullWidth ? <TopBar relative={true} /> : null}
        <Tabs fullWidth={fullWidth} />
      </AppBar>
    </div>
  ) : null;
};

export default NavBar;
