import React from "react";

import logo from "../../../assets/images/logo.jpg";
import classes from "./topBar.module.css";
import Email from "./Email/email";
import Location from "./Location/location";
import PhoneNum from "./PhoneNum/PhoneNum";

const TopBar = (props) => {
  const { relative } = props;
  return (
    <div
      className={classes.topBarContainer}
      style={
        relative
          ? { position: "relative", top: "7px", marginBottom: "7px" }
          : null
      }
    >
      <div className={classes.logoContainer}>
        <img src={logo} alt="Logo" />
      </div>
      <PhoneNum />
      <Email />
      <Location />
    </div>
  );
};

export default TopBar;
