import React from "react";

import classes from "../topBar.module.css";

const Email = (props) => {
  // Breakpoint props
  const { sm } = props;
  // Class for Element
  const emailClass = [classes.container];
  // Add class based on Breakpoint
  if (sm) {
    emailClass.push(classes.smContainer);
  }

  return (
    <div className={emailClass.join(" ")}>
      <div className={classes.iconContainer}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="28"
          viewBox="0 0 24 24"
          width="28"
          style={{ fill: "gold", marginRight: "10%" }}
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
        </svg>
      </div>
      <div className={classes.textContainer}>
        <div>EMAIL</div>
        <div><a href="mailto:info@appiwat.com">info@appiwat.com</a></div>
      </div>
    </div>
  );
};

export default Email;
