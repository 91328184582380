import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { scrollToTop } from "../../utilities/Scroll/utilities";

// Route with gtag implement
const RouteX = (props) => {
  const { path, component: Component, exact, pageTitle } = props;
  const location = useLocation();

  //set gtag for google analytic
  useEffect(() => {
    window.gtag("config", process.env.REACT_APP_GTAG_ID, {
      page_title: pageTitle,
      page_path: path,
    });
  }, [location.pathname, pageTitle, path]);

  //scroll to top everytime unmount (scroll to top when click on navbar)
  useEffect(() => {
    return scrollToTop();
  });

  return (
    <Route exact={exact} path={path}>
      <Helmet>
        <title>
          Appiwat IT -{" "}
          {pageTitle ? pageTitle : capitalize(path.replace("/", ""))}
        </title>
      </Helmet>
      <Component />
    </Route>
  );
};

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default RouteX;
