import "./App.css";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { theme } from "./theme";
import NavBar from "./components/Navbar/navBar";
import ResponsiveBar from "./components/ResponsiveBar/responsiveBar";
// import { AnimatePresence } from "framer-motion";
import MessengerCustomerChat from "react-messenger-customer-chat";
import Footer from "./components/Footer";
import Spinner from "./utilities/Spinner";
import ScrollToTop from "./utilities/Scroll/index";
import { Helmet } from "react-helmet";
import RouteX from "./utilities/RouteX";

const PortfolioPage = React.lazy(() => import("./routes/portfolio"));
const OurServicesPage = React.lazy(() => import("./routes/our-services"));
const MemberPage = React.lazy(() => import("./routes/member"));
const HomePage = React.lazy(() => import("./routes/homepage"));
const BlogPage = React.lazy(() => import("./routes/blogs"));
const BlogsArticle = React.lazy(() => import("./routes/blogs-article"));
const PageNotFound = React.lazy(() => import("./routes/404-error"));
const PethAI = React.lazy(() => import("./routes/peth-ai"));

const App = () => {
  return (
    <>
      <div style={{ overflowX: "hidden" }}>
        <MessengerCustomerChat
          pageId={process.env.REACT_APP_PAGE_ID}
          appId=""
        />
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG_ID}`}
          />
        </Helmet>
        <ScrollToTop />
        <Router>
          <Switch>
            <RouteX
              exact
              pageTitle="Peth AI"
              path="/peth-ai"
              component={PethAI}
            />
            <Router>
              <ThemeProvider theme={theme}>
                <div
                  className="fb-customerchat"
                  attribution="setup_tool"
                  page_id={process.env.REACT_APP_PAGE_ID}
                ></div>
                <CssBaseline />
                <ResponsiveBar />
                {/* <AnimatePresence exitBeforeEnter> */}
                <NavBar />
                <Suspense fallback={<Spinner />}>
                  <Switch>
                    <RouteX
                      exact
                      pageTitle="Our Services"
                      path="/our-services"
                      component={OurServicesPage}
                    />
                    <RouteX
                      exact
                      pageTitle="Our Member"
                      path="/member"
                      component={MemberPage}
                    />
                    <RouteX
                      exact
                      pageTitle="Portfolio"
                      path="/portfolio"
                      component={PortfolioPage}
                    />
                    <RouteX
                      exact
                      pageTitle="Blogs"
                      path="/blogs"
                      component={BlogPage}
                    />
                    <RouteX
                      exact
                      path="/blogs/:slug"
                      component={BlogsArticle}
                    />
                    <RouteX
                      pageTitle="404"
                      path="/404"
                      component={PageNotFound}
                    />
                    <RouteX
                      exact
                      pageTitle="Homepage"
                      path="/"
                      component={HomePage}
                    />
                    <Redirect to="/" />
                  </Switch>
                </Suspense>
                {/* </AnimatePresence> */}
                <Footer />
              </ThemeProvider>
            </Router>
          </Switch>
        </Router>
      </div>
    </>
  );
};

export default App;
